import { createContext, useContext } from "react";

// TEMPORARY: for client demo
// TODO: @TimM remove context when migrating
const AttributeContext = createContext();

export const AttributeProvider = (props) => {
  const {value, children} = props

  return (
    <AttributeContext.Provider value={value}>
      {children}
    </AttributeContext.Provider>
  );
};

export const useAttributeContext = () => useContext(AttributeContext);
